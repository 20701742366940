var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$getVisibleNames("teaching.ramicro", false, "RA Micro")))]),_c('th',[_vm._v("Resultado de Aprendizaje")])])]),_c('tbody',[_c('tr',{staticClass:"learning-result-tr"},[_c('td',[(_vm.studyUnit)?_c('div',{staticClass:"text-left ml-2"},[_vm._v(" "+_vm._s(_vm.studyUnit.name)+" ")]):_vm._e()]),_c('td',[_c('div',{staticClass:"text-left mt-2"},[_c('SentenceContainer',{staticStyle:{"font-size":"18px !important"},attrs:{"Sentence":_vm.studyUnit}})],1)])])])]),_c('div',[_c('div',{staticClass:"flex-row mt-2 mb-1"},[_c('div',{staticClass:"flex-expand"},[_c('b-form-group',{staticClass:"m-0",attrs:{"label-cols-sm":"5","label-for":"weighing"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"weighing-label"},[_c('div',{staticClass:"label-content"},[_vm._v("Ponderación Equivalente:")])])]},proxy:true}])},[_c('div',{attrs:{"id":"toggle_in_weeks"}},[_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"toggle-types noprint"},[_c('span',{class:{
                    'font-weight-bold':
                      _vm.studyUnit.automatic_achievement_weighing,
                  }},[_vm._v("Si")]),_c('b-button',{staticClass:"check-type",attrs:{"variant":"primary"},on:{"click":_vm.changedStudyUnitAutomaticAchivement}},[_c('b-icon',{staticClass:"icon-check",class:{
                      'icon-check-active':
                        !_vm.studyUnit.automatic_achievement_weighing,
                    },attrs:{"icon":"circle-fill","scale":"0.7"}})],1),_c('span',{class:{
                    'font-weight-bold':
                      !_vm.studyUnit.automatic_achievement_weighing,
                  }},[_vm._v("No")])],1)])])])],1),_c('div',[(
            _vm.remainingEvaluationCriteriaWeighing > 0 ||
            (_vm.studyUnit && _vm.studyUnit.automatic_achievement_weighing)
          )?_c('ButtonMedium',{attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
            'manual.criterio_de_evaluacion',
            false,
            'Criterio de Evaluación'
          )}`,"click_button":() => _vm.$bvModal.show('modal-evaluation-criteria'),"text_button":`Agregar`,"custom_class":``,"icon":'plus',"variant":'primary'}}):[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary.left",value:(
              'Se ha alcanzado el 100% de ponderación.'
            ),expression:"\n              'Se ha alcanzado el 100% de ponderación.'\n            ",modifiers:{"hover":true,"v-secondary":true,"left":true}}]},[_c('ButtonMedium',{attrs:{"tooltip_text":``,"text_button":`Agregar ${_vm.$getVisibleNames(
                'manual.criterio_de_evaluacion',
                false,
                'Criterio de Evaluación'
              )}`,"custom_class":``,"disabled_button":true,"icon":'plus',"variant":'primary'}})],1)]],2)]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-indicators"},model:{value:(_vm.collapse_criterias),callback:function ($$v) {_vm.collapse_criterias=$$v},expression:"collapse_criterias"}},[(_vm.evaluationCriteriaItems.length > 0)?[_c('div',{staticClass:"pl-3"},[_c('b-table-simple',{attrs:{"small":"","responsive":""}},[_c('b-thead',[_c('b-tr',[(_vm.allows_crud && _vm.draggableList.length > 0)?_c('b-th',{staticClass:"text-center",staticStyle:{"width":"3%"}}):_vm._e(),_c('b-th',{staticClass:"text-center",staticStyle:{"width":"50%"}},[_c('strong',[_vm._v(" "+_vm._s(String( this.$getVisibleNames( "manual.criterio_de_evaluacion", false, "Criterio de Evaluación" ) ))+" ")])]),(!_vm.studyUnit.automatic_achievement_weighing)?_c('b-th',{staticClass:"text-center",staticStyle:{"width":"50%"}},[_c('strong',[_vm._v(" Ponderación ")])]):_vm._e(),_c('b-th',{staticClass:"text-center",staticStyle:{"width":"3%"}})],1)],1),_c('draggable',{attrs:{"disabled":!_vm.allows_crud,"tag":"b-tbody","handle":".handle"},model:{value:(_vm.draggableList),callback:function ($$v) {_vm.draggableList=$$v},expression:"draggableList"}},[(_vm.draggableList.length > 0)?_vm._l((_vm.draggableList),function(evaluation_criteria){return _c('b-tr',{key:evaluation_criteria.id},[(_vm.allows_crud)?_c('b-td',{staticClass:"text-center handle",class:{ grabbable: _vm.allows_crud }},[(_vm.allows_crud)?_c('b-icon',{attrs:{"icon":"arrows-expand","scale":"1.2"}}):_vm._e()],1):_vm._e(),_c('b-td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(evaluation_criteria.full_sentence))]),(!_vm.studyUnit.automatic_achievement_weighing)?_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(evaluation_criteria.weighing)+"%")]):_vm._e(),(_vm.allows_crud)?_c('b-td',{staticClass:"text-center"},[_c('div',{staticClass:"text-center button-section"},[_c('ButtonSmall',{class:``,attrs:{"tooltip_text":`Editar`,"click_button":() =>
                            _vm.launchEvaluationCriteriaForm(
                              evaluation_criteria.id
                            ),"icon":'square'}}),(!isNaN(evaluation_criteria.id))?_c('ButtonSmall',{class:``,attrs:{"click_button":() => _vm.askForDeleteCriteria(evaluation_criteria.id),"tooltip_text":`Eliminar`,"icon":'trash'}}):_vm._e()],1)]):_vm._e()],1)}):_vm._e()],2)],1)],1)]:[_c('div',{staticClass:"text-center small"},[_c('em',[_vm._v("No hay "+_vm._s(_vm.$getVisibleNames( "manual.criterio_de_evaluacion", true, "Criterios de Evaluación" ))+" ")])])]],2)],1),_c('b-modal',{attrs:{"id":"modal-evaluation-criteria","ignore-enforce-focus-selector":"#sidebar-right","title":_vm.selected_evaluation_citeria
        ? String(
            'Editar ' +
              _vm.$getVisibleNames(
                'manual.criterio_de_evaluacion',
                false,
                'Criterio de Evaluación'
              )
          )
        : String(
            'Crear ' +
              _vm.$getVisibleNames(
                'manual.criterio_de_evaluacion',
                false,
                'Criterio de Evaluación'
              )
          ),"size":"lg","hide-footer":""},on:{"hide":_vm.resetSelectedEvaluationCriteria}},[_c('EvaluationCriteriaForm',{attrs:{"EvaluationCriteria":_vm.selected_evaluation_citeria,"study_unit_id":_vm.study_unit_id,"max_weighing":_vm.selected_evaluation_citeria
          ? _vm.remainingEvaluationCriteriaWeighing +
            _vm.selected_evaluation_citeria.weighing
          : _vm.remainingEvaluationCriteriaWeighing,"order":_vm.evaluationCriteriaList.length + 1},on:{"created":_vm.slotCreatedEvaluationCriteriaForm,"updated":_vm.slotUpdatedEvaluationCriteriaForm}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }