<template>
  <div>
    <table>
      <thead>
        <tr>
          <th>{{ $getVisibleNames("teaching.ramicro", false, "RA Micro") }}</th>
          <th>Resultado de Aprendizaje</th>
        </tr>
      </thead>
      <tbody>
        <tr class="learning-result-tr">
          <td>
            <div v-if="studyUnit" class="text-left ml-2">
              {{ studyUnit.name }}
            </div>
          </td>
          <td>
            <div class="text-left mt-2">
              <SentenceContainer
                :Sentence="studyUnit"
                style="font-size: 18px !important"
              >
              </SentenceContainer>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <div>
      <div class="flex-row">
        <b-button
          size="sm"
          variant="none"
          class="mr-2"
          @click="collapse_indicators = !collapse_indicators"
        >
          <b-icon-chevron-right
            v-if="!collapse_indicators"
          ></b-icon-chevron-right>
          <b-icon-chevron-down v-else></b-icon-chevron-down>
        </b-button>

        <div>Indicadores de Logro de las Competencias Genéricas</div>
      </div>
      <b-collapse
        id="collapse-indicators"
        class="mt-2"
        v-model="collapse_indicators"
      >
        <template v-if="performanceIndicatorItems.length > 0">
          <div class="pl-3">
            <b-table
              :fields="indicators_fields"
              :items="performanceIndicatorItems"
              responsive
              small
              thead-tr-class="primary-color"
            >
              <template v-slot:cell(competence)="data">
                <div class="text-center">
                  <b-avatar
                    v-bind:style="{ backgroundColor: data.value.color }"
                    :text="data.value.short_name"
                    style="border: 2px solid white"
                    :title="data.value.name"
                  ></b-avatar>
                </div>
              </template>
            </b-table>
          </div>
        </template>
        <template v-else>
          <div class="text-center small">
            <em>No hay Indicadores de Logro de las Competencias Genéricas</em>
          </div>
        </template>
      </b-collapse>
    </div> -->
    <div>
      <div class="flex-row mt-2 mb-1">
        <!-- <b-button
          size="sm"
          variant="none"
          class="mr-2"
          @click="collapse_criterias = !collapse_criterias"
        >
          <b-icon-chevron-right
            v-if="!collapse_criterias"
          ></b-icon-chevron-right>
          <b-icon-chevron-down v-else></b-icon-chevron-down>
        </b-button> -->

        <!-- <div class="flex-expand">Indicadores de Logro</div> -->
        <div class="flex-expand">
          <b-form-group label-cols-sm="5" label-for="weighing" class="m-0">
            <template #label>
              <div class="weighing-label">
                <div class="label-content">Ponderación Equivalente:</div>
              </div>
            </template>
            <div id="toggle_in_weeks">
              <div class="d-flex mt-1">
                <div class="toggle-types noprint">
                  <span
                    :class="{
                      'font-weight-bold':
                        studyUnit.automatic_achievement_weighing,
                    }"
                    >Si</span
                  >
                  <b-button
                    class="check-type"
                    variant="primary"
                    @click="changedStudyUnitAutomaticAchivement"
                  >
                    <b-icon
                      icon="circle-fill"
                      class="icon-check"
                      :class="{
                        'icon-check-active':
                          !studyUnit.automatic_achievement_weighing,
                      }"
                      scale="0.7"
                    ></b-icon>
                  </b-button>
                  <span
                    :class="{
                      'font-weight-bold':
                        !studyUnit.automatic_achievement_weighing,
                    }"
                    >No</span
                  >
                </div>
              </div>
            </div>
          </b-form-group>
        </div>
        <div>
          <ButtonMedium
            v-if="
              remainingEvaluationCriteriaWeighing > 0 ||
              (studyUnit && studyUnit.automatic_achievement_weighing)
            "
            :tooltip_text="`Agregar ${$getVisibleNames(
              'manual.criterio_de_evaluacion',
              false,
              'Criterio de Evaluación'
            )}`"
            :click_button="() => $bvModal.show('modal-evaluation-criteria')"
            :text_button="`Agregar`"
            :custom_class="``"
            :icon="'plus'"
            :variant="'primary'"
          >
          </ButtonMedium>
          <!-- <b-button
            v-if="
              remainingEvaluationCriteriaWeighing > 0 ||
              (studyUnit && studyUnit.automatic_achievement_weighing)
            "
            size="sm"
            variant="primary"
            @click="$bvModal.show('modal-evaluation-criteria')"
            >Agregar
            {{
              $getVisibleNames(
                "manual.criterio_de_evaluacion",
                false,
                "Criterio de Evaluación"
              )
            }}</b-button
          > -->
          <!-- <template v-else> Se ha alcanzado el 100% de ponderación. </template> -->
          <template v-else>
            <div
              v-b-tooltip.hover.v-secondary.left="
                'Se ha alcanzado el 100% de ponderación.'
              "
            >
              <ButtonMedium
                :tooltip_text="``"
                :text_button="`Agregar ${$getVisibleNames(
                  'manual.criterio_de_evaluacion',
                  false,
                  'Criterio de Evaluación'
                )}`"
                :custom_class="``"
                :disabled_button="true"
                :icon="'plus'"
                :variant="'primary'"
              >
              </ButtonMedium>
            </div>
            <!-- <b-button
              size="sm"
              class="btn-disabled"
              pressed
              v-b-tooltip.hover.v-secondary.left="
                'Se ha alcanzado el 100% de ponderación.'
              "
              ><b-icon icon="exclamation-circle"></b-icon> Agregar
              {{
                $getVisibleNames(
                  "manual.criterio_de_evaluacion",
                  false,
                  "Criterio de Evaluación"
                )
              }}</b-button
            > -->
          </template>
        </div>
      </div>
      <!-- <b-tabs>
        <b-tab title="Criterios de Evaluación">-->
      <b-collapse
        id="collapse-indicators"
        class="mt-2"
        v-model="collapse_criterias"
      >
        <template v-if="evaluationCriteriaItems.length > 0">
          <div class="pl-3">
            <b-table-simple small responsive>
              <b-thead>
                <b-tr>
                  <b-th
                    style="width: 3%"
                    class="text-center"
                    v-if="allows_crud && draggableList.length > 0"
                  >
                  </b-th>
                  <b-th style="width: 50%" class="text-center">
                    <strong>
                      {{
                        String(
                          this.$getVisibleNames(
                            "manual.criterio_de_evaluacion",
                            false,
                            "Criterio de Evaluación"
                          )
                        )
                      }}
                      <!-- Ponderación actions -->
                    </strong>
                  </b-th>
                  <b-th
                    v-if="!studyUnit.automatic_achievement_weighing"
                    style="width: 50%"
                    class="text-center"
                  >
                    <strong> Ponderación </strong>
                  </b-th>
                  <b-th style="width: 3%" class="text-center"> </b-th>
                </b-tr>
              </b-thead>
              <draggable
                v-model="draggableList"
                :disabled="!allows_crud"
                tag="b-tbody"
                handle=".handle"
              >
                <template v-if="draggableList.length > 0">
                  <b-tr
                    v-for="evaluation_criteria in draggableList"
                    :key="evaluation_criteria.id"
                  >
                    <b-td
                      class="text-center handle"
                      :class="{ grabbable: allows_crud }"
                      v-if="allows_crud"
                      ><b-icon
                        v-if="allows_crud"
                        icon="arrows-expand"
                        scale="1.2"
                      ></b-icon
                    ></b-td>
                    <b-td class="text-left">
                      {{ evaluation_criteria.full_sentence }}</b-td
                    >
                    <b-td
                      v-if="!studyUnit.automatic_achievement_weighing"
                      class="text-center"
                    >
                      {{ evaluation_criteria.weighing }}%</b-td
                    >
                    <b-td class="text-center" v-if="allows_crud">
                      <div class="text-center button-section">
                        <ButtonSmall
                          :tooltip_text="`Editar`"
                          :click_button="
                            () =>
                              launchEvaluationCriteriaForm(
                                evaluation_criteria.id
                              )
                          "
                          :class="``"
                          :icon="'square'"
                        ></ButtonSmall>
                        <!-- <button-edit
                          @click="
                            launchEvaluationCriteriaForm(evaluation_criteria.id)
                          "
                        ></button-edit> -->
                        <ButtonSmall
                          v-if="!isNaN(evaluation_criteria.id)"
                          :click_button="
                            () => askForDeleteCriteria(evaluation_criteria.id)
                          "
                          :tooltip_text="`Eliminar`"
                          :class="``"
                          :icon="'trash'"
                        ></ButtonSmall>
                        <!-- <button-delete
                          v-if="!isNaN(evaluation_criteria.id)"
                          @click="askForDeleteCriteria(evaluation_criteria.id)"
                        ></button-delete> -->
                      </div>
                    </b-td>
                  </b-tr>
                </template>
              </draggable>
            </b-table-simple>
            <!-- <b-table
              :fields="criterias_fields"
              :items="evaluationCriteriaItems"
              responsive
              small
              hover
              thead-tr-class="primary-color"
              thead-class="text-center"
            >
              <template v-slot:cell(weighing)="data">
                <div class="text-center">
                  {{ data.value + " %" }}
                </div>
              </template>
              <template v-slot:cell(actions)="data">
                <div class="text-center button-section">
                  <button-edit
                    @click="launchEvaluationCriteriaForm(data.item.id)"
                  ></button-edit>
                  <button-delete
                    v-if="!isNaN(data.item.id)"
                    @click="askForDeleteCriteria(data.item.id)"
                  ></button-delete>
                </div>
              </template>
            </b-table> -->
          </div>
        </template>
        <template v-else>
          <div class="text-center small">
            <em
              >No hay
              {{
                $getVisibleNames(
                  "manual.criterio_de_evaluacion",
                  true,
                  "Criterios de Evaluación"
                )
              }}
            </em>
          </div>
        </template>
      </b-collapse>
      <!-- </b-tab> -->
      <!-- <b-tab title="Indicadores de Desempeño">
          <template v-if="performanceIndicatorItems.length > 0">
            <div class="pl-3">
              <b-table
                :fields="indicators_fields"
                :items="performanceIndicatorItems"
                responsive
                small
                thead-tr-class="primary-color"
                thead-class="text-center"
              >
                <template v-slot:cell(competence)="data">
                  <div class="text-center">
                    <b-avatar
                      v-bind:style="{
                        backgroundColor: data.item.competence.color,
                      }"
                      v-b-tooltip.left.v-secondary="
                        data.item.competence.name +
                        ': ' +
                        data.item.competence.sentence
                      "
                      :text="data.item.competence.short_name"
                      style="border: 2px solid white; margin-right: 5px"
                    ></b-avatar>
                  </div>
                </template>
                <template v-slot:cell(sentence)="data">
                  {{ data.item.sentence }}
                </template>
              </b-table>
            </div>
          </template>
          <template v-else>
            <div class="text-center small">
              <em>No hay Criterios de Evaluación de las Competencias Genéricas</em>
            </div>
          </template>

           <PerformanceIndicator
            :cycle_id="1"
            :performance_indicator_id="4"
          ></PerformanceIndicator> 
        </b-tab> -->
      <!-- </b-tabs> -->
    </div>
    <!-- Modals -->
    <b-modal
      id="modal-evaluation-criteria"
      ignore-enforce-focus-selector="#sidebar-right"
      :title="
        selected_evaluation_citeria
          ? String(
              'Editar ' +
                $getVisibleNames(
                  'manual.criterio_de_evaluacion',
                  false,
                  'Criterio de Evaluación'
                )
            )
          : String(
              'Crear ' +
                $getVisibleNames(
                  'manual.criterio_de_evaluacion',
                  false,
                  'Criterio de Evaluación'
                )
            )
      "
      size="lg"
      hide-footer
      @hide="resetSelectedEvaluationCriteria"
    >
      <EvaluationCriteriaForm
        :EvaluationCriteria="selected_evaluation_citeria"
        :study_unit_id="study_unit_id"
        :max_weighing="
          selected_evaluation_citeria
            ? remainingEvaluationCriteriaWeighing +
              selected_evaluation_citeria.weighing
            : remainingEvaluationCriteriaWeighing
        "
        :order="evaluationCriteriaList.length + 1"
        @created="slotCreatedEvaluationCriteriaForm"
        @updated="slotUpdatedEvaluationCriteriaForm"
      ></EvaluationCriteriaForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
import draggable from "vuedraggable";

export default {
  name: "EvaluationCriteria",
  components: {
    draggable,
    EvaluationCriteriaForm: () =>
      import("@/components/reusable/EvaluationCriteriaForm"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    ButtonMedium: () =>
      import("@/components/reusable/Buttons/Medium/ButtonMedium"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
    // PerformanceIndicator: () =>
    //   import("@/components/reusable/PerformanceIndicator"),
  },
  props: {
    study_unit_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapse_indicators: false,
      collapse_criterias: true,
      selected_evaluation_citeria: null,
    };
  },
  computed: {
    ...mapGetters({
      matters: names.MATTERS,
      egressProfileMatters: names.EGRESS_PROFILES_MATTERS,
      studyUnits: names.STUDY_UNITS,
      evaluationCriterias: names.EVALUATION_CRITERIAS,
      performanceIndicators: names.PERFORMANCE_INDICATORS,
      institutionCompetences: names.INSTITUTION_COMPETENCES,
      competences: names.COMPETENCES,
      competenceTypes: names.COMPETENCE_TYPES,
    }),
    indicators_fields() {
      return [
        {
          key: "competence",
          label: String(
            this.$getVisibleNames("mesh.competence", false, "Competencia")
          ),
        },
        { key: "sentence", label: "Indicador de Desempeño de las CG" },
      ];
    },
    draggableList: {
      get() {
        let list = this.evaluationCriteriaItems;
        list.sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) element.order = order;
          order += 1;
        });
        return list;
      },
      set(list) {
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) {
            element.order = order;
            if (!isNaN(element.id)) {
              this.updatedEvaluationCriteria(element);
            }
          }
          order += 1;
        });
      },
    },
    // criterias_fields() {
    //   return [
    //     {
    //       key: "sentence",
    //       label: String(
    //         this.$getVisibleNames(
    //           "manual.criterio_de_evaluacion",
    //           false,
    //           "Criterio de Evaluación"
    //         )
    //       ),
    //       thClass: "font-weight-bold",
    //     },
    //     { key: "weighing", label: "Ponderación", thClass: "font-weight-bold" },
    //     { key: "actions", label: "" },
    //   ];
    // },
    studyUnit() {
      return this.studyUnits.find((x) => x.id == this.study_unit_id);
    },
    matter() {
      if (!this.studyUnit) return null;
      return this.matters.find((x) => x.id == this.studyUnit.matter);
    },
    egressProfileMatter() {
      if (!this.studyUnit) return null;
      let profile_matter = this.egressProfileMatters.find(
        (x) => x.matter == this.studyUnit.matter
      );
      return profile_matter ? profile_matter.id : null;
    },
    performanceIndicatorList() {
      let list = [];
      this.studyUnit.performance_indicators.forEach((element) => {
        let instance = this.performanceIndicators.find((x) => x.id == element);
        if (instance) list.push(instance);
      });
      return list;
    },
    performanceIndicatorItems() {
      let list = [];
      this.performanceIndicatorList.forEach((element) => {
        const competence = this.institutionCompetences.find(
          (x) => x.id == element.institution_competence
        );
        list.push({
          id: element.id,
          sentence:
            element.action + " " + element.content + " " + element.condition,
          competence: competence,
        });
      });
      return list;
    },
    evaluationCriteriaList() {
      return this.evaluationCriterias
        .filter((x) => x.study_unit == this.study_unit_id)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    evaluationCriteriaItems() {
      let list = [];
      this.evaluationCriteriaList.forEach((element) => {
        const indicator = this.performanceIndicators.find(
          (x) => x.id == element.performance_indicator
        );
        if (element.competence == null) {
          list.push({
            id: element.id,
            sentence: element.full_sentence,
            full_sentence: element.full_sentence,
            indicator: indicator,
            competence: null,
            weighing: element.weighing,
          });
        } else {
          const competence = this.competences.find(
            (x) => x.id == element.competence
          );
          list.push({
            id: element.id,
            sentence: element.full_sentence,
            full_sentence: element.full_sentence,
            indicator: indicator,
            competence: competence,
            weighing: element.weighing,
          });
        }
      });
      return list;
    },
    // institutionCompetenceItems() {
    //   let list = [];
    //   this.evaluationCriteriaList.forEach((element) => {
    //     const indicator = this.performanceIndicators.find(
    //       (x) => x.id == element.performance_indicator
    //   );
    //   });
    //   return list;
    // },
    // evaluationCriteriaItems() {
    //   let list = [];

    //   this.evaluationCriteriaList.forEach((element) => {
    //     const indicator = this.performanceIndicators.find(
    //       (x) => x.id == element.performance_indicator
    //     );

    //     if (element.competence == null) {
    //       list.push({
    //         id: element.id,
    //         sentence:
    //           element.action + " " + element.content + " " + element.condition,
    //         indicator: indicator,
    //         disciplinar_competence: null,
    //         professional_competence: null,
    //         weighing: element.weighing,
    //       });
    //     } else {
    //       const competence = this.competences.find(
    //         (x) => x.id == element.competence
    //       );

    //       if (
    //         this.competences.find((x) => x.id == element.competence).type == 1
    //       ) {
    //         list.push({
    //           id: element.id,
    //           sentence:
    //             element.action +
    //             " " +
    //             element.content +
    //             " " +
    //             element.condition,
    //           indicator: indicator,
    //           disciplinar_competence: competence,
    //           professional_competence: null,
    //           weighing: element.weighing,
    //         });
    //       } else {
    //         list.push({
    //           id: element.id,
    //           sentence:
    //             element.action +
    //             " " +
    //             element.content +
    //             " " +
    //             element.condition,
    //           indicator: indicator,
    //           disciplinar_competence: null,
    //           professional_competence: competence,
    //           weighing: element.weighing,
    //         });
    //       }
    //     }
    //   });

    //   return list;
    // },
    remainingEvaluationCriteriaWeighing() {
      let weighing = 0;
      this.evaluationCriteriaList.forEach((element) => {
        weighing += element.weighing;
      });
      return 100 - weighing;
    },
    // competenceType(){
    //   this.data.item.type = this.competenceTypes.find(
    //     (x) => x.type == id
    //   );
    //   console.log(type);
    //   return type;
    // },
  },
  methods: {
    updatedEvaluationCriteria(evaluation_criteria) {
      this.$store.dispatch(names.PATCH_EVALUATION_CRITERIA, {
        evaluation_criterio_id: evaluation_criteria.id,
        item: { order: evaluation_criteria.order },
      });
    },
    changedStudyUnitAutomaticAchivement() {
      if (this.studyUnit)
        this.$store
          .dispatch(names.PATCH_STUDY_UNIT, {
            study_unit_id: this.studyUnit.id,
            item: {
              automatic_achievement_weighing:
                !this.studyUnit.automatic_achievement_weighing,
            },
          })
          .then(() => {
            if (this.studyUnit && this.studyUnit.automatic_achievement_weighing)
              this.$restful
                .Get(
                  `teaching/toggle_study_unit_automatic_achievement_weighing/?study_unit=${this.studyUnit.id}`
                )
                .then(() => {
                  this.$store.dispatch(names.FETCH_EVALUATION_CRITERIAS, {
                    study_unit_id: this.studyUnit.id,
                  });
                });
          });
    },
    resetSelectedEvaluationCriteria() {
      this.selected_evaluation_citeria = null;
    },
    launchEvaluationCriteriaForm(evaluation_criteria_id) {
      this.selected_evaluation_citeria = this.evaluationCriterias.find(
        (x) => x.id == evaluation_criteria_id
      );
      this.$bvModal.show("modal-evaluation-criteria");
    },
    slotUpdatedEvaluationCriteriaForm() {
      this.$bvModal.hide("modal-evaluation-criteria");
      this.selected_evaluation_citeria = null;
    },
    slotCreatedEvaluationCriteriaForm() {
      this.$bvModal.hide("modal-evaluation-criteria");
    },
    askForDeleteCriteria(id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "manual.criterio_de_evaluacion",
          false,
          "Criterio de Evaluación"
        )}"?`,
        text: "Esta acción no se podrá revertir.",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) this.deleteCriteria(id);
      });
    },
    deleteCriteria(id) {
      this.$store.dispatch(names.DELETE_EVALUATION_CRITERIA, id).then(() => {
        toast(
          this.$getVisibleNames(
            "manual.criterio_de_evaluacion",
            false,
            "Criterio de Evaluación"
          ) + " Eliminado."
        );
        if (this.studyUnit && this.studyUnit.automatic_achievement_weighing)
          this.$restful
            .Get(
              `teaching/toggle_study_unit_automatic_achievement_weighing/?study_unit=${this.study_unit_id}`
            )
            .then(() => {
              this.$store.dispatch(names.FETCH_EVALUATION_CRITERIAS, {
                study_unit_id: this.study_unit_id,
              });
            });
      });
    },
  },
};
</script>

<style scoped>
p {
  font-size: 26px !important;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-expand {
  flex-grow: 4;
}
td,
table {
  width: -webkit-fill-available;
  margin-left: 2%;
}
th {
  background: var(--primary-color) !important;
  /* border: 1px solid var(--secondary-color); */
  padding: 0px 5px;
  text-align: center !important;
}
.button-section {
  display: flex;
}
.competence {
  text-align: center !important;
}
.learning-result-tr {
  background: rgb(245, 245, 245);
}
.competence-badge {
  font-size: 0.8rem;
  margin-top: 6%;
  padding: 8%;
  min-width: 125px;
  letter-spacing: 0.3px;
  font-size: 14px;
  background-color: var(--primary-color);
  color: var(--secondary-font-color);
  cursor: default;
}
.institution-competence-indicator {
  display: flex;
}
.btn-disabled {
  background-color: var(--secondary-color);
  opacity: 0.7;
  border: 1px solid var(--secondary-color);
}
.btn-disabled:hover {
  background-color: var(--secondary-color);
}
.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.toggle-types {
  display: flex;
  float: right;
  padding: 0.3em 1.8em;
  border-radius: 3px;
}
.check-type {
  background: #007bff !important;
  margin-inline: 0.7em;
  height: 1.12em;
  width: 2.2em !important;
  margin-top: 2%;
  border-radius: 10px;
}
.check-type-disabled {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ) !important;
  margin-inline: 0.7em;
  height: 1.12em;
  width: 2.2em !important;
  margin-top: 2%;
  border-radius: 10px;
}
.icon-check {
  color: white;
  transform: translate(-69%, -50%);
  transition: 0.2s;
}
.icon-check-active {
  transform: translate(15%, -50%) !important;
}
</style>